// SelectVideoModal.tsx
import React, { useState, useEffect, useRef } from 'react';
import CloseIcon from "@material-ui/icons/Close";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  CircularProgress,
  makeStyles,
  Theme,
  createStyles,
  Typography,
  IconButton,
  Tabs,
  Tab,
  Button,
} from '@material-ui/core';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import StopIcon from '@material-ui/icons/Stop';
import {VideoAttrs} from 'models/video';
import {useDispatch, useSelector} from 'react-redux';
import {Videos} from 'api/agent';
import {setVideos} from 'redux/reducers/editor';
import VideoUploadForm from './new';
import Alert from '@material-ui/lab/Alert';
import {T} from 'components/utils/t';

interface SelectVideoModalProps {
  open: boolean;
  onClose: () => void;
  onSelect: (video: VideoAttrs) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    searchField: {
      marginBottom: theme.spacing(2),
    },
    list: {
      maxHeight: 400,
      overflow: 'auto',
    },
    loading: {
      display: 'flex',
      justifyContent: 'center',
      padding: theme.spacing(2),
    },
    videoItem: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    videoHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
    },
    audioControls: {
      marginTop: theme.spacing(1),
      display: 'flex',
      alignItems: 'center',
    },
    videoPlayer: {

    },
    description: {
      fontSize: '0.9rem',
      color: theme.palette.text.secondary,
    },
    tabContent: {
      padding: theme.spacing(2),
    },
  })
);

const SelectVideoModal: React.FC<SelectVideoModalProps> = ({ open, onClose, onSelect }) => {
  const classes = useStyles();
  const videos:Array<VideoAttrs> = useSelector((state:any) => state.editor?.videos);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [activeVideo, setActiveVideo] = useState<VideoAttrs|null>(null);
  const [activeTab, setActiveTab] = useState<number>(0);
  const [youtubeUrl, setYoutubeUrl] = useState<string>('');
  const [youtubeVideoId, setYoutubeVideoId] = useState<string>('');
  const [youtubeVideoName, setYoutubeVideoName] = useState<string>('');
  const [error, setError] = useState<string>('');
  const dispatch = useDispatch();

  useEffect(() => {
    if (open) {
      refetchVideos();
    }
    // Cleanup on close
    return () => {
      setActiveVideo(null);
      setYoutubeUrl(null);
      setYoutubeVideoName('');
    };
  }, [open, searchQuery]);

  const refetchVideos = () => {
    Videos.list({ query: searchQuery }).then((videos) => {
      dispatch(setVideos(videos));
    });
  }

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const handleSelect = (video: VideoAttrs) => {
    onSelect(video);
  };

  const handlePlay = (video: VideoAttrs) => {
    if (activeVideo?.uuid === video.uuid) {
      setActiveVideo(null);
    } else {
      setActiveVideo(video);
    }
  };

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setActiveTab(newValue);
  };

  const handleYoutubeUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setYoutubeUrl(e.target.value);
  };

  const handleYoutubeVideoNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setYoutubeVideoName(e.target.value);
  };

  const handleYoutubePreview = () => {
    const videoId = youtubeUrl.split('v=')[1];
    if (videoId) {
      setYoutubeVideoId(videoId);
    }
  };

  const handleYoutubeSubmit = () => {
    if (!youtubeUrl || !youtubeVideoName) return;

    Videos.create(youtubeVideoName, youtubeUrl).then((video) => {
      onSelect(video);
    }).catch((error) => {
      setError(error)
    });

  };

  if(!videos) return null;

  // Group videos by category
  const groupedVideos = videos.reduce<Record<string, VideoAttrs[]>>((groups, video) => {
    const category = 'Uncategorized';
    if (!groups[category]) {
      groups[category] = [];
    }
    groups[category].push(video);
    return groups;
  }, {});

  const OneVideo = ({ video:snd, hit }: { video?: VideoAttrs, hit?:any}) => {

    let video;
    if(snd) {
      video = snd;
    } else {
      const {id, uuid, name, duration, description, url} = hit;
      video = {id, uuid, name, duration, description, url};
    }

    return <ListItem button key={video.uuid} onClick={() => handleSelect(video)}>
      <div className={classes.videoItem}>
        <div className={classes.videoHeader}>
          <ListItemText primary={video.name} />
          <IconButton
            edge="end"
            aria-label={activeVideo?.uuid === video.uuid ? 'Stop preview' : 'Play preview'}
            onClick={(e) => {
              e.stopPropagation();
              handlePlay(video);
            }}
          >
            {activeVideo?.uuid === video.uuid ? <StopIcon /> : <PlayArrowIcon />}
          </IconButton>
        </div>
        <Typography variant="body2" className={classes.description}>
          ({video.duration} sec.) {video.description}
        </Typography>
      </div>
    </ListItem>
  };

  return (<>
  {activeVideo && <Dialog open={!!activeVideo} onClose={() => setActiveVideo(null)} fullWidth maxWidth="sm">
    <DialogTitle>
      {activeVideo?.name}
        <IconButton
          aria-label="close"
          onClick={()=>setActiveVideo(null)}
          style={{ marginRight: -12, float: 'right' }} // Adjust spacing as needed
        >
          <CloseIcon />
        </IconButton>

    </DialogTitle>
    <DialogContent>
      <video controls className={classes.videoPlayer}>
        <source src={activeVideo?.url} type="video/mp4" />
      </video>
    </DialogContent>
  </Dialog>}

    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>
        <T k="videos.select_video_modal.dialog_title">Select a Video</T>
        <IconButton
          aria-label="close"
          onClick={onClose}
          style={{ marginRight: -12, float: 'right' }} // Adjust spacing as needed
        >
          <CloseIcon />
        </IconButton>

      </DialogTitle>
      <DialogContent>
        <Tabs value={activeTab} onChange={handleTabChange} indicatorColor="primary" textColor="primary">
          <Tab label="Your videos" />
          <Tab label="Create video" />
          <Tab label="YouTube URL" />
        </Tabs>
        <div className={classes.tabContent}>
          {activeTab === 0 && (
            <>
              <TextField
                label="Search Videos"
                variant="outlined"
                fullWidth
                className={classes.searchField}
                value={searchQuery}
                onChange={handleSearchChange}
              />
              {loading ? (
                <div className={classes.loading}>
                  <CircularProgress />
                </div>
              ) : (
                <List className={classes.list}>
                  {Object.entries(groupedVideos)
                    .sort(([a, videosA], [b, videosB]) => videosB.length - videosA.length)
                    .map(([category, videos]) => (
                      <div key={category}>
                        <ListSubheader>{category}</ListSubheader>
                        {videos.map((video) => (
                          <OneVideo key={video.uuid} video={video} />
                        ))}
                      </div>
                    ))}
                  {videos.length === 0 && !loading && (
                    <ListItem>
                      <ListItemText primary="No videos found." />
                    </ListItem>
                  )}
                </List>
              )}
            </>
          )}

          {activeTab === 1 && (
            <VideoUploadForm onCreated={(video)=>{handleSelect(video)}} />
          )}

          {activeTab === 2 && (
            <>
              {error && <Alert severity="error">{error}</Alert>}
              <TextField
                label="YouTube URL"
                variant="outlined"
                fullWidth
                className={classes.searchField}
                value={youtubeUrl}
                onChange={handleYoutubeUrlChange}
              />
              <TextField
                label="Video Name"
                variant="outlined"
                fullWidth
                className={classes.searchField}
                value={youtubeVideoName}
                onChange={handleYoutubeVideoNameChange}
              />
              <IconButton onClick={handleYoutubePreview}>
                <PlayArrowIcon />
              </IconButton>
              {youtubeVideoId && (
                <div>
                  <iframe
                    width="100%"
                    height="315"
                    src={`https://www.youtube.com/embed/${youtubeVideoId}`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleYoutubeSubmit}
                    style={{ marginTop: '1rem' }}
                  >
                    Submit
                  </Button>
                </div>
              )}
            </>
          )}

        </div>
      </DialogContent>
    </Dialog>
  </>);
};

export default SelectVideoModal;

