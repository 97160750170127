import {Sounds} from "api/agent";
import {Flex} from "components/flex";
import {GlitchEventAction} from "components/glitch/space";
import {parseGradients, serializeItems} from "components/glitch/utils";
import {generateGameBackground} from "components/glitch_editor";
import SelectSoundModal from "components/sounds/select_sound_modal";
import {useDesignContext} from "contexts/design_context";
import React, {FC, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setBackgroundMusic, setGameData, setSounds, setSynced} from "redux/reducers/editor";
import {addCallback} from "utils/channels";
import {SIZES} from "utils/prompt_modifiers";
import {sdxl_url } from "utils/urls";
import CloseIcon from "@material-ui/icons/Close";

import {
  Card,
  CardContent,
  CardActions,
  makeStyles,
  Theme,
  createStyles,
  Button, Dialog, DialogContent, DialogTitle, Typography, Tabs, Tab, IconButton
} from '@material-ui/core';
import ClearableTextField from "components/clearable_text_field";
import {saveGame} from "components/canvas/Header";
import {AdvancedAiForm, getDefaultConfig} from "components/panels/generative_ai_panel";
import ActionsConfig from "components/actions_config";
import {PLAY_SOUND_ACTION, PLAY_VIDEO_ACTION, START_SCENE_EVENT} from "game/game_constants";
import {T} from "components/utils/t";

const DEFAULT_CONFIG = {
  prompt: "game background for an adventure game, with a forest and a castle in the background.",
  negativePrompt: "bad, ugly",
  model: 'sdxl',
  numOutputs: 1,
  width: 4000,
  height: 2000,
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: theme.spacing(2),
    },
    card: {
      width: '100%',
      maxWidth: 400,
    },
    songInfo: {
      marginBottom: theme.spacing(1),
    },
    selectButton: {
      marginTop: theme.spacing(1),
    },
  })
);

const SelectSound = ({modalOpen, setModalOpen, sound:selectedSong, setSound}:any & {setEventConfig:any, eventConfig: GlitchEventAction}) => {

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Selected Song
          </Typography>
          {selectedSong ? (
            <div>
              <Typography variant="subtitle1" className={classes.songInfo}>
                {selectedSong.name}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {selectedSong.description}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Duration: {selectedSong.duration} sec
              </Typography>
            </div>
          ) : (
            <Typography variant="body2" color="textSecondary">
              No song selected
            </Typography>
          )}
        </CardContent>
        <CardActions>
          <Button
            variant="contained"
            color="primary"
            onClick={()=>setModalOpen(true)}
            className={classes.selectButton}
          >
            {selectedSong ? 'Change Song' : 'Select Song'}
          </Button>
        </CardActions>
      </Card>

      <SelectSoundModal
        open={modalOpen}
        onClose={()=>setModalOpen(false)}
        onSelect={setSound}
        selectedTab={'search_music'}
      />
    </div>
  );
}

const ConfigModal:FC<any> = (props) => {

  const {fabricCanvas,designController}= useDesignContext();
  const generativePrompt = useSelector((state:any) => state.editor?.generativePrompt);
  const gameData = useSelector((state:any) => state.editor?.gameData);

  const bgUrl = gameData?.background_image?.url;

  const [value, setValue] = useState(getDefaultConfig(generativePrompt, 'background_panel', DEFAULT_CONFIG));
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState('background_music');

  const dispatch = useDispatch();
  const sounds = useSelector((state:any) => state.editor?.sounds);
  const background_music = gameData?.background_music

  useEffect(() => {
    if(background_music)return;
    if(!gameData.background_music)return;

    dispatch(setBackgroundMusic(gameData.background_music));

  }, [gameData])

  useEffect(() => {
    if(!sounds)
      refetchSounds();
  }, []);

  const refetchSounds = () => {
    Sounds.list().then((sounds) => {
      dispatch(setSounds(sounds));
    });
  }

  const setModel = (v) => {
    let num_outputs=value.num_outputs;
    if(v == 'sprite'){
      num_outputs=1
    }
    setValue({
      ...value,
      model: v,
      num_outputs: num_outputs
    })
  }

  const onChange = (key, value) => {
    let dataUrl = null;
    
    const {width, height}=designController.workarea;
    const parsedGradients = parseGradients(gameData.gradient);

    parsedGradients[key] = value;

    if(parsedGradients.top && parsedGradients.bottom){
      dataUrl = generateGameBackground({width,height}, parsedGradients);
    }

    designController.setWorkareaOption({
      src: dataUrl,
      //fill: gradient,
      top: 0,
      left: 0,
      width,
      height,
    });
  }

  const width=3000, height= 1000;
  const csrf_token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
  const [modalOpen, setModalOpen] = useState(false);

  const handleSubmit = async (e: any) => {

    setLoading(true);
    const url = sdxl_url()

    const resp = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrf_token,
      },
      body: JSON.stringify({
        prompt: value.prompt,
        params: {
          width,
          height,
        }
      })
    });

    const data = await resp.json();

    addCallback(data.uuid, (data) => {
      console.log('Got response', data);
      handleResult(data.output[0])
    }, (error) => {
      console.log('Got error', error);
    })

    e.preventDefault();
  }

  const handleResult = async (url) => {
    setLoading(false);
    const gD = serializeItems(gameData,fabricCanvas);
    dispatch(setGameData({
      ...gD,
      background_image: {
        ...gD.background_image,
        url: url
      }
    }));
  };

  const parsedGradients = parseGradients(gameData.gradient);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleSaveEvents = (eventActions) => {
    const gD = serializeItems(gameData,fabricCanvas);
    dispatch(setGameData({...gD, event_actions: eventActions}));
    saveGame(fabricCanvas)
  }

  return <>
  <Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth="md">
    <DialogTitle>
      <T k="games.config_modal.title">Configure Game</T>
      <IconButton
        aria-label="close"
        onClick={props.onClose}
        style={{ marginRight: -12, float: 'right' }} // Adjust spacing as needed
      >
        <CloseIcon />
      </IconButton>
    </DialogTitle>
    <Tabs value={activeTab} onChange={handleTabChange} indicatorColor="primary" textColor="primary" centered>
      <Tab label="Game Introduction" value="game_intro"/>
      <Tab label="Generate Background" value="generate_background"/>
      <Tab label="Background Music" value="background_music"/>
    </Tabs>
    <DialogContent>
      {activeTab === "game_intro" && (
        <div>
          <Typography variant="h6">Game Introduction</Typography>
          <ActionsConfig events={[START_SCENE_EVENT]} actions={[PLAY_SOUND_ACTION, PLAY_VIDEO_ACTION]} handleSave={handleSaveEvents} event_actions={gameData.event_actions}/>
        </div>
      )}
      {activeTab === "generate_background" && (
        <Flex flexDirection="column">
          {parsedGradients.top ? 
          <Flex.Item>
            <Flex justifyContent="space-between">
              <Flex.Item>Top color:</Flex.Item>
              <Flex.Item><input type="color" value={parsedGradients.top} onChange={(e) => onChange('top', e.target.value)}/></Flex.Item>
            </Flex>
          </Flex.Item>: null }

          {parsedGradients.bottom ? 
          <Flex.Item>
            <Flex justifyContent="space-between">
              <Flex.Item>Bottom color:</Flex.Item>
              <Flex.Item><input type="color" value={parsedGradients.bottom} onChange={(e) => onChange('bottom', e.target.value)}/></Flex.Item>
            </Flex>
          </Flex.Item>: null}

          {bgUrl ?
          <Flex.Item>
            <Flex flexDirection="column">
              <Flex.Item>Background Image:</Flex.Item>
              <Flex.Item><img src={bgUrl} style={{width: '100%'}}/></Flex.Item>
              <Flex.Item style={{marginTop: '1rem'}} flex="1">
                <ClearableTextField
                  fullWidth={true}
                  minRows={4}
                  label="Generate new background"
                  multiline
                  variant="outlined"
                  value={value.prompt}
                  onChange={(e) => setValue({ ...value, prompt: e.target.value })}
                  placeholder="game asset, vector art, centered, one single water lily, on diffuse background."
                />
              </Flex.Item>

              <Flex.Item>
                {generativePrompt ? 
                <a href="#" onClick={(e) => { e.preventDefault(); setValue({...value, prompt: generativePrompt});}}>Use original prompt</a> : null}

                <AdvancedAiForm
                  setModel={setModel}
                  showAdvanced={showAdvanced}
                  setShowAdvanced={setShowAdvanced}
                  value={value}
                  sizes={SIZES}
                  setValue={setValue}/>
              </Flex.Item>

              <Flex.Item style={{marginTop: '1rem'}} flex="1">
                <Button type="submit" variant="contained" color="primary" onClick={handleSubmit} disabled={loading}>Generate</Button>
              </Flex.Item>

            </Flex>
          </Flex.Item>: null}
        </Flex>
      )}

      {activeTab === "background_music" && (
        <Flex flexDirection="column">
          <Flex.Item>
            <Flex flexDirection="column">
              <Flex.Item>Background Sound:</Flex.Item>
              <Flex.Item>
                <SelectSound
                  sound={background_music}
                  setSound={(sound) => {
                    dispatch(setSynced(false));
                    const gD = serializeItems(gameData,fabricCanvas);
                    dispatch(setGameData({...gD, background_music: sound}));
                    setModalOpen(false);
                  }}
                  modalOpen={modalOpen}
                  setModalOpen={setModalOpen}
                />
              </Flex.Item>
            </Flex>
          </Flex.Item>
        </Flex>
      )}
    </DialogContent>
  </Dialog>

  </>
}

export default ConfigModal
