// SelectTextModal.tsx
import React, { useState, useEffect } from 'react';
import CloseIcon from "@material-ui/icons/Close";
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import StopIcon from '@material-ui/icons/Stop';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  makeStyles,
  Theme,
  createStyles,
  Typography,
  Tabs,
  Tab,
  IconButton,
} from '@material-ui/core';

import { TextAttrs } from 'models/text';
import { useDispatch, useSelector } from 'react-redux';
import { Texts } from 'api/agent';
import { setTexts } from 'redux/reducers/editor';
import TextUploadForm from './new';
import { T } from 'components/utils/t';

interface SelectTextModalProps {
  open: boolean;
  onClose: () => void;
  onSelect: (text: TextAttrs) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    searchField: {
      marginBottom: theme.spacing(2),
    },
    list: {
      maxHeight: 400,
      overflow: 'auto',
    },
    loading: {
      display: 'flex',
      justifyContent: 'center',
      padding: theme.spacing(2),
    },
    textItem: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    textHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
    },
    description: {
      fontSize: '0.9rem',
      color: theme.palette.text.secondary,
    },
    tabContent: {
      padding: theme.spacing(2),
    },
  })
);

const SelectTextModal: React.FC<SelectTextModalProps> = ({ open, onClose, onSelect }) => {
  const classes = useStyles();
  const texts: Array<TextAttrs> = useSelector((state: any) => state.editor?.texts);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [activeText, setActiveText ] = useState<TextAttrs | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<number>(0);
  const dispatch = useDispatch();

  useEffect(() => {
    if (open) {
      refetchTexts();
    }
  }, [open, searchQuery]);

  const refetchTexts = () => {
    setLoading(true);
    Texts.list({ query: searchQuery }).then((texts) => {
      dispatch(setTexts(texts));
      setLoading(false);
    });
  }

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const handlePlay = (text: TextAttrs) => {
    if (activeText?.uuid === text.uuid) {
      setActiveText(null);
    } else {
      setActiveText(text);
    }
  };

  const handleSelect = (text: TextAttrs) => {
    onSelect(text);
  };

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setActiveTab(newValue);
  };

  if (!texts) return null;

  const OneText = ({ text }: { text: TextAttrs }) => {
    return (
      <ListItem button key={text.id} onClick={() => handleSelect(text)}>
        <div className={classes.textItem}>
          <div className={classes.textHeader}>
            <ListItemText primary={text.name} />
            <IconButton
              edge="end"
              aria-label={activeText?.uuid === text.uuid ? 'Stop preview' : 'Play preview'}
              onClick={(e) => {
                e.stopPropagation();
                handlePlay(text);
              }}
            >
              {activeText?.uuid === text.uuid ? <StopIcon /> : <PlayArrowIcon />}
            </IconButton>

          </div>
          <Typography variant="body2" className={classes.description}>
            {text.description}
          </Typography>
        </div>
      </ListItem>
    );
  };

  return (<>
  {activeText && <Dialog open={!!activeText} onClose={() => setActiveText(null)} fullWidth maxWidth="sm">
    <DialogTitle>
      {activeText?.name}
        <IconButton
          aria-label="close"
          onClick={onClose}
          style={{ marginRight: -12, float: 'right' }} // Adjust spacing as needed
        >
          <CloseIcon />
        </IconButton>

    </DialogTitle>
    <DialogContent>
      {/* dangerouslySetInnerHTML in the div: */}
      <div dangerouslySetInnerHTML={{ __html: activeText?.content }} />
    </DialogContent>
  </Dialog>}

    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>
        <T k="texts.select_text_modal.title">Select a Text</T>
        <IconButton
          aria-label="close"
          onClick={onClose}
          style={{ marginRight: -12, float: 'right' }} // Adjust spacing as needed
        >
          <CloseIcon />
        </IconButton>

      </DialogTitle>
      <DialogContent>
        <Tabs value={activeTab} onChange={handleTabChange} indicatorColor="primary" textColor="primary">
          <Tab label={<T k="texts.select_text_modal.your_texts">Your texts</T>} />
          <Tab label={<T k="texts.select_text_modal.create_text">Create text</T>} />
        </Tabs>
        <div className={classes.tabContent}>
          {activeTab === 0 && (
            <>
              <TextField
                label={<T k="texts.select_text_modal.search.label">Search Texts</T>}
                variant="outlined"
                fullWidth
                className={classes.searchField}
                value={searchQuery}
                onChange={handleSearchChange}
              />
              {loading ? (
                <div className={classes.loading}>
                  <CircularProgress />
                </div>
              ) : (
                <List className={classes.list}>
                  {texts.map((text) => (
                    <OneText key={text.id} text={text} />
                  ))}
                  {texts.length === 0 && !loading && (
                    <ListItem>
                      <ListItemText primary={<T k="texts.select_text_modal.no_texts_found">No texts found.</T>} />
                    </ListItem>
                  )}
                </List>
              )}
            </>
          )}

          {activeTab === 1 && (
            <TextUploadForm onCreated={(text) => { handleSelect(text) }} />
          )}

        </div>
      </DialogContent>
    </Dialog>
  </>);
};

export default SelectTextModal;
