import {GlitchDeco} from 'components/glitch/space';
import { v4 } from 'uuid';
import {CUSTOM_PROPERTIES, deco2Fabric, linesStyles, loadFabricImage2} from 'components/glitch/utils';
import {useDesignContext} from 'contexts/design_context';
import React from 'react';
import {useDrop} from 'react-dnd';
import {fabric} from 'fabric';
import {InteractionModes} from './utils';
import {findCurrentCenter} from 'components/asset_carousel';
import {useDispatch, useSelector} from 'react-redux';
import {setPinPanel, setSelectedPanel} from 'redux/reducers/editor';
import {store} from 'redux/store';
import {CanvasController} from '.';
import {NativeTypes} from 'react-dnd-html5-backend';
import { Pictures } from 'api/agent'; // Assuming you have an API agent to handle file uploads

export const addItem= async (i, centered, designController:CanvasController, cb=null) => {
  i.option = i.option || {};

  const state = store.getState();
  const {layerId, gameData} = state.editor;

  const fabricCanvas= designController.canvas;

  if (designController.interactionMode === InteractionModes.POLYGON) {
    designController.interactionHandler.selection()
  }

  const z = fabricCanvas.getObjects()
    .filter((o:any) => o.glitch_type == 'deco' && o.layer_id === layerId)
    .reduce((max,o:any) => Math.max(max,o.item.z),0) + 1;

  let img,aseprite:AsepriteJson,url, aseprite_url;

  if('sprites' in i) {
    url=i.sprites[0].public_filename;
    aseprite_url=i.sprites[0].aseprite_url;
    aseprite = await fetch(aseprite_url).then(r => r.json());
    img = await loadFabricImage2(url);
  } else {
    url = i.url || `/glitch/assets/${i.name}.svg`
    img = await loadFabricImage2(url);
  }

  const imgElem:HTMLImageElement = img.getElement() as HTMLImageElement

  if(aseprite){
    const {frames} = aseprite; //.meta.frameTags[0];
    const {frame} = Object.values(frames)[0]
    const {x,y,w,h} = frame;
    img.set({width: w, height: h, cropX: x, cropY: y})
  }

  const pt = centered ? findCurrentCenter(designController.container, fabricCanvas) : {x:i.option.left, y:i.option.top};

  //if(window.devicePixelRatio > 1){ //&& designController.canvas.getRetinaScaling() > 1){
  //  pt.x = pt.x / window.devicePixelRatio;
  //  pt.y = pt.y / window.devicePixelRatio;
  //}

  const pos = fabric.util.transformPoint(new fabric.Point(pt.x,pt.y), fabric.util.invertTransform(fabricCanvas.viewportTransform));

  const layer = gameData.dynamic.layers[layerId];
  const middlegroundHeight = gameData.dynamic.layers.middleground.h;
  const heightDiff = layer.h - middlegroundHeight;
  const [offset_x,offset_y] = layerId == 'middleground' ? [layer.w/2, layer.h] : [0,-heightDiff];

  const {maxWidth, maxHeight} = i.option;

  let newW=img.width, newH=img.height;

  if(maxWidth || maxHeight){
    const scale = Math.min((maxWidth||imgElem.naturalWidth)/imgElem.naturalWidth, (maxHeight||imgElem.naturalHeight)/imgElem.naturalHeight);
    newW = imgElem.naturalWidth * scale;
    newH = imgElem.naturalHeight * scale;
  }

  const id = v4();

  const item:GlitchDeco = {
    x: pos.x - offset_x,
    y: pos.y - offset_y,
    w: aseprite ? aseprite.meta.size.w : newW,
    h: aseprite ? aseprite.meta.size.h : newH,
    sprites: i.sprites,
    r: 0,
    h_flip: false,
    filename: i.name || id,
    url: url,
    z,
  }
  const fabricItem = deco2Fabric(item, gameData, layerId, img);

  const options = {
    id,
    glitch_type: i.glitch_type || 'deco',
    element: img.getElement()
  }

  if(aseprite){
    Object.assign(options, {
      cropX: aseprite ? aseprite.frames['0'].frame.x : undefined,
      cropY: aseprite ? aseprite.frames['0'].frame.y : undefined,
      width: aseprite ? aseprite.frames['0'].frame.w : undefined,
      height: aseprite ? aseprite.frames['0'].frame.h : undefined,
    })
  }

  const opts = Object.assign(options, fabricItem.toJSON(CUSTOM_PROPERTIES))

  return designController.add(opts, false, false, null, (createdObject) => {
    if(cb) cb(createdObject);
    else fabricCanvas.setActiveObject(createdObject);
  })
}


const DropHandler = (props) => {

  const {fabricCanvas, designController} = useDesignContext();
  const selectedPanel = useSelector((state:any)=>state.editor.selectedPanel);
  const {layerId, gameData} = useSelector((state:any)=>state.editor);
  const dispatch = useDispatch();

  const addAnchor= async (i) => {

    const name = prompt('Enter a name for the spot');
    const pt = new fabric.Point(i.option.left, i.option.top);
    const pos = fabric.util.transformPoint(pt, fabric.util.invertTransform(fabricCanvas.viewportTransform));

    const layer = gameData.dynamic.layers[layerId];
    const middlegroundHeight = gameData.dynamic.layers.middleground.h;
    const heightDiff = layer.h - middlegroundHeight;
    const [offset_x,offset_y] = layerId == 'middleground' ? [layer.w/2, layer.h] : [0,-heightDiff];
    const id = v4();

    const opts = {
      id,
      glitch_type: 'signpost',
      item: {
        id, 
        name,
        type:'signpost',
        x: pos.x + offset_x,
        y: pos.y + offset_y,
        w: 50,
        h: 50,
      },
      left: pos.x,
      top: pos.y,
      ...linesStyles.signpost
    }

    designController.add(opts, false, false, null, (createdObject) => {
      fabricCanvas.setActiveObject(createdObject);
    })
  }

  const handleFiles = async (files, pt) => {
    console.log('Files:',files, pt)
    const uploadedFiles = await Promise.all(files.filter(f => f.type.startsWith('image')).map(async (file) => {
      const response = await Pictures.create(file.name, file); // Assuming uploadFile is a function that handles the file upload
      return response
    }));

    uploadedFiles.forEach(async (fileData) => {
      const url = fileData.image_url;
      const img = await loadFabricImage2(url);
      const imgElem: HTMLImageElement = img.getElement() as HTMLImageElement;

      const pos = fabric.util.transformPoint(pt, fabric.util.invertTransform(fabricCanvas.viewportTransform));
      const id = v4();

      const item: GlitchDeco = {
        x: pos.x,
        y: pos.y,
        w: imgElem.naturalWidth,
        h: imgElem.naturalHeight,
        filename: fileData.name || id,
        url,
        z: 1, // Default z-index
      };

      const fabricItem = deco2Fabric(item, gameData, layerId, img);

      const options = {
        id,
        glitch_type: 'deco',
        element: img.getElement(),
      };

      const opts = Object.assign(options, fabricItem.toJSON(CUSTOM_PROPERTIES));

      designController.add(opts, false, false, null, (createdObject) => {
        fabricCanvas.setActiveObject(createdObject);
      });
    });
  }

  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: ['sprite', 'anchor', NativeTypes.FILE],
    drop: (item:any,monitor) => {
      const cliOffset=monitor.getClientOffset();
      const el=fabricCanvas.getElement().closest('.rde-canvas');

      const pt = new fabric.Point(el.scrollLeft+cliOffset.x, el.scrollTop+cliOffset.y);

      if (item.files) {
        handleFiles(item.files, pt);
        return;
      }

      const {option, ...rest} = item;

      const newItem = {
        ...rest,
        option: {
          ...option,
          left: pt.x,
          top: pt.y
        }
      };

      if(item.center){
        delete newItem.option.left;
        delete newItem.option.top;
      }

      if(newItem.glitch_type == 'anchor'){
        addAnchor(newItem)
      } else if(newItem.glitch_type == 'deco'){
        addItem(newItem,!!item.center, designController);
      } else{
        addItem(newItem,!!item.center, designController);
      }

      dispatch(setSelectedPanel(selectedPanel))
      dispatch(setPinPanel(false));

      return { name: 'canvas' }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }), [designController,gameData,fabricCanvas,layerId])

  const style = { width: '100%', height: '100%'};

  return (
    <div ref={drop} style={style}>
      {props.children}
    </div>
  );
}

export default DropHandler;
