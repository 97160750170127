export const PLAY_SOUND_ACTION = 'play-sound';
export const APPLY_EFFECT = 'apply-effect';
export const PLAY_VIDEO_ACTION = 'play-video';
export const TRAVEL_ACTION = 'travel';
export const SHOW_WEB_RESOURCE_ACTION= 'web-resource';
export const SHOW_TEXT_ACTION= 'show-text';

export const START_SCENE_EVENT = 'start-scene';
export const CLICK_EVENT = 'click';
export const HOVER_EVENT = 'hover';
export const COLLISION_END_EVENT = 'collision-end';
export const COLLISION_START_EVENT = 'collision-start';

export const PLATFORM_LINE_LABEL = 'platform_line';
export const WORLD_FLOOR_LABEL = 'world_floor';
export const PLAYER_LABEL = 'player';
export const LADDER_LABEL = 'ladder';
