import React, { useState, useRef, useEffect } from 'react';
import {debounce, sortBy, throttle} from 'lodash';
import {fabric} from 'fabric';
import { Helmet } from 'react-helmet';
import {ButtonGroup, Dialog, DialogContent, DialogTitle, LinearProgress} from '@material-ui/core';

import { TouchBackend } from 'react-dnd-touch-backend'
import {HTML5Backend} from 'react-dnd-html5-backend';

import { isMobile } from 'react-device-detect'

import '../stylesheets/canvas.scss';

import { FabricObjectOption, DesignCanvas, InteractionModes} from '../components/canvas';
import { useDesignContext, DesignContext, DesignProvider } from '../contexts/design_context';
import useForceUpdate from '../utils/use_force_update';
import Panels, {EditorPanel} from './panels';
import {GlitchGame, GlitchSignpost} from './glitch/space';
import {Games,  Spaces} from 'api/agent';

import Footer, {i18n} from './canvas/Footer';
import Header, {saveGame} from './canvas/Header';
import {changeHandler, workareaChangeHandler} from './canvas/change_handler';

import { parseGradients, renderLayer } from './glitch/utils';
import {SpaceAttributes} from 'models/space';
import {GameAttributes} from 'models/game';
import {DndProvider} from 'react-dnd';
import {isDrawingMode} from './canvas/handlers/BaseHandler';
import CommonButton from './common/Button';
import ItemPropertiesDrawer from './item_properties_drawer';
import AssetCarousel from './asset_carousel';
import {useDispatch, useSelector} from 'react-redux';
import { setSynced, setOpenProperties, setSpaceId, setLayerId, setAiGenerations, setPromptSuggestions, setGenerativePrompt, setBackgroundMusic, setGameData, setLoadingProgress, setGame, setOpenDoors} from 'redux/reducers/editor';
import LoadManager from 'utils/load_manager';
import {T} from './utils/t';
import DoorModal from './spaces/door_modal';
import {loadAiGenerations} from 'services/ai_generations_service';

const propertiesToInclude = [
  'id',
  'name',
  'locked',
  'file',
  'src',
  'link',
  'fitBox',
  'tooltip',
  'animation',
  'layout',
  'workareaWidth',
  'workareaHeight',
  'videoLoadType',
  'autoplay',
  'shadow',
  'muted',
  'loop',
  'code',
  'icon',
  'userProperty',
  'metadata',
  'trigger',
  'configuration',
  'superType',
  'points',
  'svg',
  'loadType',
  'aCoords',
];

const defaultOption:FabricObjectOption = {
  fill: 'rgba(0, 0, 0, 1)',
  stroke: 'rgba(255, 255, 255, 0)',
  strokeUniform: true,
  resource: {},
  animation: {
    type: 'none',
    loop: true,
    autoplay: true,
    duration: 1000,
  },
  userProperty: {},
  metadata: {}
};

export const generateGameBackground = ({height,width},{top,bottom}) => {
  const gradientSvgString = `
  <svg width="${width}" height="${height}" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <linearGradient id="grad1" x1="0%" y1="0%" x2="0%" y2="100%">
        <stop offset="0%" style="stop-color:${top};stop-opacity:1" />
        <stop offset="100%" style="stop-color:${bottom};stop-opacity:1" />
      </linearGradient>
    </defs>
    <rect width="${width}" height="${height}" fill="url(#grad1)" />
  </svg>
      `
  var encoded = encodeURIComponent(gradientSvgString);
  return "data:image/svg+xml," + encoded;
}


function GlitchEditor(props:any={width: 800, height: 600}){

  const forceUpdate = useForceUpdate();

  const context = useDesignContext();
  const {
    space,
    setSpace,
    designController,
    selectedObject,
    setSelectedObject,
    fabricCanvas,
  } = context;

  const [doParallax, _setDoParallax] = useState(true);
  const dispatch = useDispatch();

  const [zoomRatio, setZoomRatio] = useState<number|null>(1)
  const [fullscreen, setFullscreen]  = useState<any|null>(false);
  const [loadProgress, setLoadProgress] = useState<number>(0);

  const canvasRef = useRef<DesignCanvas>(null);//{current:null};

  const {
    layerId,
    spaceId,
    background_music,
    game,
    gameData,
    openDoors,
    openProperties,
    interactionMode,
    loadingProgress,
  } = useSelector((state:any)=>state.editor);


  const width = gameData ? gameData.dynamic.r - gameData.dynamic.l : 800;
  const height = gameData ? gameData.dynamic.b - gameData.dynamic.t : 600;

  const updateParallaxOnScroll = (event: React.UIEvent<HTMLDivElement>):any => {
    if(!doParallax)
      return;
    const scroll = event.currentTarget.scrollLeft;

    const totalWidth = fabricCanvas.width;
    const viewportWidth = window.innerWidth;

    const currentPercent = scroll / (totalWidth - viewportWidth);

    fabricCanvas.forEachObject((o:any) => {
      if(!o.parallax)return

      const parallaxOffset = (totalWidth - viewportWidth) * currentPercent * o.parallax;

      o.left = o.item.x + o.offset_x + parallaxOffset;
    })

    fabricCanvas.requestRenderAll()
  }

  const canvasOption = {
    width, 
    height, 
    enableRetinaScaling: false,
    preserveObjectStacking: true,
    perPixelTargetFind: true,
  }

  const uuid = props.match?.params?.id || 'GM410QQ0CHARO';

  useEffect(()=> {
    (async () => {

      let glitch_game_json:GlitchGame;
      let space_json:SpaceAttributes|null=null
      let game_json:GameAttributes|null=null

      if(uuid.length < 36){
        glitch_game_json = await $.get('/locations/' + uuid + '.json')
      }else{
        space_json = await Spaces.show(uuid)
        game_json = await Games.show(space_json.game.uuid)
        if(space_json.location){
          glitch_game_json = space_json.location.metadata
        }
      }

      if(glitch_game_json){
        //setContextGameData(glitch_game_json);
        dispatch(setGameData(glitch_game_json));
        if(glitch_game_json.background_image?.generator){
          dispatch(setGenerativePrompt(glitch_game_json.background_image.generator))
        }

        if(glitch_game_json.background_music){
          dispatch(setBackgroundMusic(glitch_game_json.background_music))
        }

        dispatch(setSpaceId(uuid));
        dispatch(setGame(game_json));
        if(space_json){
          setSpace(space_json);
        }
      }
    })()
  },[uuid])


  useEffect(() => {
    if(!gameData) return;
    if(!fabricCanvas) return;

    //highlightSelectedObject(fabricCanvas);

    (window as any).fabricCanvas=fabricCanvas;
    (window as any).gameData=gameData;

    designController.setDimensions({width,height});

    fabricCanvas.forEachObject((o:any) => {
      if(o.id == 'workarea') return;
      fabricCanvas.remove(o);
    });

    const totalLayers = Object.keys(gameData.dynamic.layers).length;

    Promise.all(Object.keys(gameData.dynamic.layers).map((id,i) => {
      return renderLayer(gameData,id,fabricCanvas).then((o:any) => {
        dispatch(setLoadingProgress(100*i/totalLayers));
      });
    })).then(() => {

      const parsedGradients = gameData.gradient ? parseGradients(gameData.gradient) : {};

      let bgUrl = null;
      if(parsedGradients.top && parsedGradients.bottom){
        bgUrl = generateGameBackground({width,height}, parsedGradients);
      }
      if(gameData.background_image){
        bgUrl = gameData.background_image.url
      }

      designController.setWorkareaOption({
        src: bgUrl,
        //fill: gradient,
        top: 0,
        left: 0,
        width,
        height,
      }).then(() => {
        dispatch(setLoadingProgress(100));
      });

      designController.restackItems();

      fabricCanvas.requestRenderAll()
    })

    designController.gameData = gameData;

  }, [gameData, fabricCanvas])


  useEffect(()=>{
    if(!fabricCanvas || !designController) return;
    designController.autosave=true;

    const doSave = throttle(() => {
      saveGame(context.fabricCanvas);
    }, 3000, {leading:false, trailing: true})

    designController.save = ()=>{
      dispatch(setSynced(false));
      doSave();
    }

  },[fabricCanvas, designController, gameData, spaceId, background_music])

  loadAiGenerations(spaceId);

  useEffect(() => {
    if(!fabricCanvas) return;
    if(!designController) return;

    LoadManager.instance.on('progress', (progress, src) => {
      //console.log('progress',progress, src)
      setLoadProgress(progress);
    });
    LoadManager.instance.on('start', () => {
      //console.log('progress start')
    })
    LoadManager.instance.on('complete', () => {
      //console.log('progress complete')
      setLoadProgress(0);
    });

    return () => {
      designController.destroy();
      fabricCanvas.dispose()
      LoadManager.instance.off('progress');
      LoadManager.instance.off('start');
      LoadManager.instance.off('complete');

      dispatch(setOpenProperties(false));
      dispatch(setSpaceId(null));
      dispatch(setAiGenerations([]));
      dispatch(setPromptSuggestions([] as any));
      dispatch(setGameData(null));
    }
  }, [fabricCanvas, designController])


  const handleChange= (selectedItem, changedValues, allValues) => {
    const changedKey = Object.keys(changedValues)[0];
    const changedValue = changedValues[changedKey];

    if (allValues.workarea) {
      workareaChangeHandler(changedKey, changedValue, allValues.workarea, canvasRef);
    }else{
      changeHandler(selectedItem, changedValues, allValues, canvasRef)
    }
  }

  const handleContext= (event, target, controller) => {
    console.log('handleContext',event, target, controller)
  };

  const handleSelect= (target) => {
    if(!fabricCanvas) return;
    if(target?.id == 'workarea')
      return;

    if (target?.id){
      setSelectedObject(target);

      if(target.glitch_type == 'ladder' || target.glitch_type == 'wall'){
        fabricCanvas.uniformScaling = false;
      } else {
        fabricCanvas.uniformScaling = true;
      }

      if(target.layer_id)
        dispatch(setLayerId(target.layer_id));
    } else {
      setSelectedObject(target);
    }
  };

  const handleRemove= () => {
    handleSelect(null);
  }

  const handleAdd= (target) => {
    forceUpdate();

    if (target.type === 'activeSelection') {
      const activeSelection = target as fabric.ActiveSelection;
      activeSelection.forEachObject((obj: any) => {
        obj.set({layer_id: layerId})
      });
      handleSelect(null);
      return;
    }

    target.set({layer_id: layerId});

    if(designController)
      designController.select(target);

    if(fabricCanvas)
      fabricCanvas.requestRenderAll();
  }

  const onChangeFullscreen = (e,doFullscreen) => {
    setFullscreen(doFullscreen);
    if(doFullscreen)
      document.querySelector('#root').requestFullscreen();
    else
      document.exitFullscreen();

  }

  const setDesignCanvas = (node) => {
    canvasRef.current=node;
  }

  const backend = isMobile ? TouchBackend : HTML5Backend;
  const options = isMobile ? { enableMouseEvents: true } : {};

  return <>
      <Helmet>
        <title>{`Edit "${space?.name}" in game "${game?.name}"`}</title>
      </Helmet>

  <div className="rde-layout">
    <DndProvider backend={backend} options={options}>
      <div className="rde-editor">
        {canvasRef.current && <Panels/>}
        {canvasRef.current && spaceId && gameData && <EditorPanel canvasRef={canvasRef}  onChange={handleChange}/>}

        {openProperties && <ItemPropertiesDrawer />}
        {game && designController && openDoors && <DoorModal open={openDoors} onClose={()=>dispatch(setOpenDoors(false))} selectedObject={selectedObject}/>}

        <div className="rde-editor-canvas-container">
          <div className="rde-loading">
            {(!!loadProgress) && <LinearProgress variant="determinate" value={loadProgress} />}
          </div>
          <div className="rde-editor-header-toolbar">
            {(canvasRef.current && <Header onChange={handleChange} />)}
          </div>
          <div className="rde-editor-canvas" style={{overflow: 'scroll'}}>
            <DesignContext.Consumer>
              {({setFabricCanvas, setDesignController}) => <>
                 <DesignCanvas
                   updateParallaxOnScroll={updateParallaxOnScroll}
                   responsive={false}
                   width={width}
                   height={height}
                   workareaOption={(width && height) ? {width, height, workareaWidth: width, workareaHeight: height} : {}}
                   guidelineOption={{enabled: false}}
                   setFabricCanvas={setFabricCanvas}
                   setCanvasController={(c)=>{
                     setDesignController(c)
                   }}
                   ref={setDesignCanvas}
                   editable={true}
                   minZoom={5}
                   maxZoom={500}
                   objectOption={defaultOption}
                   canvasOption={canvasOption}
                   propertiesToInclude={propertiesToInclude}
                   onModified={debounce(() => { forceUpdate(); },300)}
                   onAdd={handleAdd}
                   onRemove={handleRemove}
                   onSelect={handleSelect}
                   onZoom={(zoom) => setZoomRatio(zoom) }
                   onContext={handleContext}
                   onTransaction={(transaction) => forceUpdate()}
                   keyEvent={{ transaction: true, }}
                 />
              </>}
            </DesignContext.Consumer>
          </div>

          {canvasRef.current && interactionMode == InteractionModes.ADD_SPRITE && <AssetCarousel/>}
          <div className="rde-editor-footer-toolbar">
            {(canvasRef.current && spaceId && gameData) ? <Footer fullscreen={fullscreen} onChangeFullscreen={onChangeFullscreen} zoomRatio={zoomRatio} /> : null}
          </div>

      <Dialog open={loadingProgress != 100} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title"><T k="glitch_editor.loading">Loading</T></DialogTitle>
        <DialogContent>
          <LinearProgress variant="determinate" value={loadingProgress} />
        </DialogContent>
      </Dialog>

    {isDrawingMode(interactionMode) &&
    <div className="rde-editor-footer-toolbar-esc">
      <ButtonGroup>
        <CommonButton  onClick={() => designController.drawingHandler.handleFinishDrawing()} icon="x" tooltipTitle={i18n.t('action.esc')} />
      </ButtonGroup>
  </div>}

        </div>
      </div>
    </DndProvider>
  </div>
</>
}

export default (props) => {
  return <DesignProvider>
    <GlitchEditor {...props}/>
  </DesignProvider>
}

export {defaultOption}
