import React, { CSSProperties } from 'react';
import { InstantSearch, SearchBox, InfiniteHits } from "react-instantsearch";
import type { GlitchAsset } from 'models/glitch/asset';
import CloseIcon from "@material-ui/icons/Close";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  makeStyles,
  Theme,
  createStyles,
  IconButton,
} from '@material-ui/core';

import {result_attributes, searchAssets, search_assets_config} from "utils/searchkit_client";
import {T} from 'components/utils/t';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loading: {
      display: 'flex',
      justifyContent: 'center',
      padding: theme.spacing(2),
    },
    soundHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
    },
    description: {
      fontSize: '0.9rem',
      color: theme.palette.text.secondary,
    },
    tabContent: {
      padding: theme.spacing(2),
    },
  })
);

interface SelectSpriteModalProps {
  open: boolean;
  onClose: () => void;
  onSelect: (sprite: GlitchAsset) => void;
}

const SelectSpriteModal: React.FC<SelectSpriteModalProps> = ({ open, onClose, onSelect }) => {
  
  const index = `glitch_assets_${(window as any).rails_env}`;
  const classes = useStyles();

  const HitView = ({ hit , ...props}) => {

    const svgStyle:React.CSSProperties = {
      width: '10rem',
      height: '10rem',
      objectFit: 'contain',
      display: 'inline-block',
      marginRight: '1rem',
      border: '1px solid black',
      borderRadius: '.5rem',
    };
    const obj = result_attributes<GlitchAsset>(hit, search_assets_config.search_settings.result_attributes)

    const style:CSSProperties = {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    };

    return <div style={style} onClick={() => onSelect(obj)}>
      <img src={obj.url} style={svgStyle} alt={obj.name}></img>

      <p>{hit.name_tokens}</p>
    </div>
    };

  return <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
    <DialogTitle>
      <T k="sprites.select_sprite_modal.title">Select a Sprite</T>
        <IconButton
          aria-label="close"
          onClick={onClose}
          style={{ marginRight: -12, float: 'right' }} // Adjust spacing as needed
        >
          <CloseIcon />
        </IconButton>

    </DialogTitle>
      <DialogContent>
        <div className={classes.tabContent}>

          <InstantSearch indexName={index} searchClient={searchAssets} future={{preserveSharedStateOnUnmount: true}} initialUiState={{[index]:{query:'door'}}}>

            <SearchBox />
            <InfiniteHits hitComponent={HitView} classNames={{item: 'nd-inline-block col-2', list: 'row'}} />
          </InstantSearch>
        </div>
      </DialogContent>
    </Dialog>;
}


export default SelectSpriteModal;
