import React from 'react';
import { useEffect, useState } from 'react';
import dagre from 'dagre';
import {SpotAttrs} from 'components/actions_config';

const SpaceConnections = ({ apiData }:{apiData:SpotAttrs[]}) => {

  const [positions, setPositions] = useState({});
  const [svgDimensions, setSvgDimensions] = useState({ width: 800, height: 600 });

  useEffect(() => {
    const g = new dagre.graphlib.Graph();
    g.setGraph({
      rankdir: "TB",
      ranker: "network-simplex",
      align: "UL"
    });
    g.setDefaultEdgeLabel(() => ({}));

    apiData.forEach((item) => {
      g.setNode(item.space.uuid, { width: 100, height: 100 });
    });

    apiData.forEach((item) => {
      item.signpost.connects?.forEach((connection) => {
        g.setEdge(item.space.uuid, connection.space_id);
      });
    });

    dagre.layout(g);

    const calculatedPositions = {};
    let maxX = 0;
    let maxY = 0;
    g.nodes().forEach((node) => {
      if (!g.node(node)) return;
      const { x, y } = g.node(node);
      calculatedPositions[node] = { x, y };
      if (x > maxX) maxX = x;
      if (y > maxY) maxY = y;
    });

    setPositions(calculatedPositions);
    setSvgDimensions({ width: maxX + 100, height: maxY + 100 });
  }, [apiData]);

  return (
    <svg width={svgDimensions.width} height={svgDimensions.height} style={{ border: '1px solid #ccc' }}>
      <defs>
        <marker
          id="arrowhead"
          markerWidth="10"
          markerHeight="7"
          refX="10"
          refY="3.5"
          orient="auto"
          markerUnits="strokeWidth"
        >
          <path d="M0,0 L10,3.5 L0,7 Z" fill="#FF6347" />
        </marker>
      </defs>

      {apiData.map((item) => {
        const { uuid, preview_url, name } = item.space;
        const position = positions[uuid];
        if (!position) return null;

        return (
          <g key={uuid} transform={`translate(${position.x - 50}, ${position.y - 50})`}>
            <image
              href={preview_url}
              width={100}
              height={100}
              className="space"
              stroke="#1E90FF"
              strokeWidth={2}
            />
            <text x={50} y={120} textAnchor="middle">
              {name}
            </text>
          </g>
        );
      })}

      {apiData.map((item) => {
        const fromSpace = item.space.uuid;
        const fromPosition = positions[fromSpace];

        return item.signpost.connects?.map((connection) => {
          const toSpace = connection.space_id;
          const toPosition = positions[toSpace];

          if (fromPosition && toPosition) {
            return (
              <line
                key={`${fromSpace}-${toSpace}`}
                x1={fromPosition.x}
                y1={fromPosition.y}
                x2={toPosition.x}
                y2={toPosition.y}
                className="signpost-arrow"
                stroke="#FF6347"
                strokeWidth={2}
                markerEnd="url(#arrowhead)"
              />
            );
          }
          return null;
        });
      })}
    </svg>
  );
};

export default SpaceConnections;
