import GamePreview from './preview';
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { ListPagination } from 'components/shared/list_pagination';
import { Games } from 'api/agent';
import { GameAttributes } from 'models/game';
import { withRouter, useLocation, Link } from 'react-router-dom'
import {new_creatix_game_url, game_welcome_url, play_game_url} from 'utils/urls';
import {useSelector} from 'react-redux';
import {T} from 'components/utils/t';

function GameList({ limit }) {

  const currentUser = useSelector((state:any) => state.common.currentUser);
  const location = useLocation();

  const [games, setGames] = useState<GameAttributes[]>([]);
  const [pagination, setPagination] = useState(null);

  const showOnlyMine = location.pathname === '/games/my';
  let isMounted = true;

  useEffect(() => {
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    async function fetchGames() {
      const params = showOnlyMine ? { mine: true, q: '*' } : {};
      const {data, pagination} = await Games.all(params);
      if(!isMounted) return;
      setGames(data.slice(0, limit || data.length));
      setPagination(pagination);
    }

    fetchGames();
  }, [limit, showOnlyMine, location.pathname]);

  const fetchPage = (page) => {
    const params = showOnlyMine ? { mine: true, q: '*', page } : { page };
    Games.all(params).then(({data, pagination}) => {
      if(!isMounted) return;
      setGames(data);
      setPagination(pagination);
    });
  };

  return (
    <>
      <Helmet>
        <title>{showOnlyMine ? `Your games` : `All the games in viwoc`}</title>
      </Helmet>

      <div className="row my-4">
        <div className="col-12 my-4">
          <ul className="nav nav-pills my-4">
            <li className="nav-item">
              <Link className={`nav-link ${!showOnlyMine ? 'active' : ''}`} to="/games">
                <T k="games.list.all_games">All Games</T>
              </Link>
            </li>
            {currentUser && <li className="nav-item">
              <Link className={`nav-link ${showOnlyMine ? 'active' : ''}`} to="/games/my">
                <T k="games.list.my_game">My Games</T>
              </Link>
            </li>}
            <li className="nav-item">
              <Link className={`nav-link`} to="/creatix/1">
                <T k="games.list.create_new_game">Create new game</T>
              </Link>
            </li>
          </ul>

        </div>

        {games.map((game) => {
          let url = game_welcome_url(game.uuid)
          if(game.start_at){
            url = play_game_url(game.start_at.space.uuid)
          }
          return (
            <div className="col-12 col-md-3 mb-4" key={`container-${game.uuid}`} >
              <GamePreview game={game} currentUser={currentUser} onClick={() => window.open(`/#${url}`, '_blank')}/>
            </div>
          );
        })}

        <div className="col-12">
          {pagination ? <ListPagination pagination={pagination} pager={fetchPage} /> : null}
        </div>

        <div className="col-12 col-md-3 my-4">
          <Link className="btn btn-primary btn-xl p-4" to={new_creatix_game_url()}><T k="games.list.create_game_btn">Create Game</T></Link>
        </div>

      </div>
    </>
  );
}

export default withRouter(GameList)
