import { createConsumer, Cable, Channel } from "@rails/actioncable";
import {store} from "redux/store";

interface User {
  id: string;
  token: string;
}

class ConnectionManager {
  public subscriptions:{ [k:string]:Array<{success?:(any)=>void, error?:(any)=>void}>}= {}

  public requests:{[k:string]:Array<{success?:(any)=>void, error?:(any)=>void}>} = {}

  public ai_subscription : any

  public consumer: Cable | null = null;
  public subscription: Channel | null = null;
  public currentUser: User | null = null;

  constructor() {
    this.currentUser = store?.getState?.()?.common?.currentUser;
    this.setupConnection();
    this.listenToStoreChanges();
  }

  get isConnected(){
    return this.subscription?.connected;
  }

  setupConnection() {
    if (this.currentUser) {
      this.consumer = createConsumer();

      this.subscription = this.consumer.subscriptions.create(
	{channel: "AiGenerationChannel"},
	{
	  connected: this.onConnected.bind(this),
	  disconnected: this.onDisconnected.bind(this),
	  rejected: this.onRejected.bind(this),
	  received: this.onReceived.bind(this),
	}
      );
    }
  }

  onConnected() {
    console.log('Connected');
  }

  onDisconnected() {
    console.log('Disconnect');
    Object.entries(this.requests).forEach(([uuid, r]) => {
      r.forEach((cb) => cb.error?.('Disconnected'));
    })
  }

  onRejected() {
    console.log('Rejected');
  }

  onReceived({action, payload}) {
    switch(action){
	//case 'uuid':
	//  console.log('Take UUID:', payload);
	//  delete requests[payload.prompt];
	//  requests[payload.uuid]=payload.prompt;
	//  break;
      case 'error':
	console.log('Take Error:', payload);
	if(this.requests[payload.uuid]){
	  const r=this.requests[payload.uuid];
	  delete this.requests[payload.uuid];
	  r.forEach((cb) => cb.error?.(payload));
	}

        {
          const id = `${payload.related_type}:${payload.related_id}`;
          if(this.subscriptions[id]?.length){
            const s=this.subscriptions[id];
            s.forEach((cb) => cb.error?.(payload));
          }
        }

	break;
      case 'result':
	console.log('Take Result:', payload);
	if(this.requests[payload.uuid]){
	  const r=this.requests[payload.uuid];
	  delete this.requests[payload.uuid];
	  r.forEach((cb) => cb.success?.(payload));
	}

	const id = `${payload.related_type}:${payload.related_id}`;
	if(this.subscriptions[id]?.length){
	  const s=this.subscriptions[id];
	  s.forEach((cb) => cb.success?.(payload));
	}
	break;
      case 'finished':
	  //cable.disconnect()
    }
  }

  private teardownConnection() {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = null;
    }

    if (this.consumer) {
      this.consumer.disconnect();
      this.consumer = null;
    }
  }

  private listenToStoreChanges() {
    store.subscribe(() => {
      const newUser = store.getState().common?.currentUser;
      if (newUser !== this.currentUser) {
        this.currentUser = newUser;
        this.resetConnection();
      }
    });
  }

  public resetConnection() {
    this.teardownConnection();
    this.setupConnection();
  }
}

let comms:ConnectionManager | null = null

function setupConnection(){
  if(store){
    comms= new ConnectionManager();
  }else{
    setTimeout(setupConnection, 500);
  }
}
document.addEventListener('DOMContentLoaded', setupConnection);

if(window.location.toString().match(/dev./)){
  (window as any).comms = comms;
}

// add a subscription to some results, return a function that allows the subscriber to unsubscribe
export const subscribeToResults = (subscribable_type:string, subscribable_id:string, onSuccess,onError?) => {
  const id = `${subscribable_type}:${subscribable_id}`;
  comms.subscriptions[id] = comms.subscriptions[id] || [];

  comms.subscriptions[id].push({
    success: onSuccess,
    error: onError
  })

  return () => {
    comms.subscriptions[id] = comms.subscriptions[id].filter((s) => s.success !== onSuccess && s.error !== onError);
  }
}

export const addCallback = (uuid, onSuccess,onError?) => {
  if(!comms.isConnected){
    console.warn('Not connected to the server');
    comms.resetConnection();
  }

  comms.requests[uuid] = comms.requests[uuid] || [];

  comms.requests[uuid].push({
    success: onSuccess,
    error: onError
  })
  return true;
}

export const waitForResultWithTimeout = (uuid, timeout=10):Promise<any> => {
  return new Promise((resolve, reject) => {
    if(!addCallback(uuid, resolve, reject)){
      reject('Not connected to the server');
    }
    setTimeout(() => {
      if(comms.requests[uuid]){
        reject('Timeout');
      }
    }, timeout*1000);
  })
}
