import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { ArrowArea } from "react-arrow-master";
import { SpriteCard } from 'components/sprites/sprite_card';
import el_svg from 'images/pointer-click.svg';
import {T} from 'components/utils/t';

export default function HowTo({selectedSprite, handleNext}) {
  return <Dialog
    disableBackdropClick
    disableEscapeKeyDown
    className="game_welcome step3"
    open={true}
    onClose={()=>handleNext(3)}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    maxWidth="sm"
  >
    <DialogTitle id="alert-dialog-title-2" disableTypography={true}>
      <T k="game_welcome.how_to.title">Use the arrow keys to move your character, click on items to interact.</T>
    </DialogTitle>

    <DialogContent>
      <ArrowArea arrows={[{ from: {id: "a",posX: 'right', posY: 'middle'}, to: {id: "b", posX: 'left', posY: 'top'}}]}>
        <div id='inner-content'>
          {selectedSprite && <SpriteCard
            playing={true}
            sprite={selectedSprite}
            anim='right'
            hideBtns={true}
            html={{className:"img", id:'a'}}
            key={selectedSprite.id}></SpriteCard>}
          <img src={el_svg} style={{maxWidth: '100px'}} id='b'></img>
        </div>
      </ArrowArea>
    </DialogContent>
    <DialogActions>
      <Button onClick={()=>{handleNext(3)}} color="primary">
        Next
      </Button>
    </DialogActions>
  </Dialog>
}

