import React, { useState } from "react";
import axios from "axios";
import { TextField, Button, Select, MenuItem, InputLabel, FormControl, Typography, Container } from "@material-ui/core";

const FormularioDNI = () => {
  const [dniImages, setDniImages] = useState([]);
  const [formData, setFormData] = useState([]);

  const handleImageUpload = (e) => {
    setDniImages(Array.from(e.target.files));
  };

  const extractDataFromImages = async () => {
    if (!dniImages.length) return alert("Por favor, sube imágenes del DNI.");

    const formData = new FormData();
    dniImages.forEach((file) => {
      formData.append("files", file);
    });

    try {
      const response = await axios.post("/id_recognition", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      const extractedData = response.data; // Suponemos que el backend devuelve un array con los datos
      setFormData(extractedData);
    } catch (error) {
      console.error("Error al procesar las imágenes", error);
      alert("No se pudieron procesar las imágenes del DNI.");
    }
  };

  const handleSubmit = () => {
    const mossosFileContent = formData.map(data => (
      `${data.tipoRegistro}|${data.numeroDocumento}|${data.tipoDocumento}|${data.nombre}|${data.apellido1}|${data.apellido2}|${data.fechaNacimiento}|${data.paisNacionalidad}|${data.fechaEntrada}|${data.horaEntrada}|${data.fechaSalida}|${data.horaSalida}`
    )).join("\n");

    const blob = new Blob([mossosFileContent], { type: "text/plain" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "datos_mossos.txt";
    link.click();
  };

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" gutterBottom>
        Formulario de Registro
      </Typography>
      <FormControl fullWidth margin="normal">
        <input
          accept="image/*"
          type="file"
          multiple
          onChange={handleImageUpload}
          style={{ display: "none" }}
          id="dniUpload"
        />
        <label htmlFor="dniUpload">
          <Button variant="contained" color="primary" component="span">
            Subir Imágenes del DNI
          </Button>
        </label>
      </FormControl>
      <Button
        variant="contained"
        color="secondary"
        fullWidth
        onClick={extractDataFromImages}
      >
        Procesar DNIs
      </Button>

      {formData.map((data, index) => (
        <div key={index} style={{ marginTop: "20px" }}>
          <Typography variant="h6">DNI {index + 1}</Typography>
          <TextField
            label="Número de Documento"
            value={data.numeroDocumento}
            onChange={(e) => {
              const updatedFormData = [...formData];
              updatedFormData[index].numeroDocumento = e.target.value;
              setFormData(updatedFormData);
            }}
            fullWidth
            margin="normal"
          />

          <FormControl fullWidth margin="normal">
            <InputLabel>Tipo de Documento</InputLabel>
            <Select
              value={data.tipoDocumento}
              onChange={(e) => {
                const updatedFormData = [...formData];
                updatedFormData[index].tipoDocumento = e.target.value;
                setFormData(updatedFormData);
              }}
            >
              <MenuItem value="D">DNI</MenuItem>
              <MenuItem value="N">NIE</MenuItem>
              <MenuItem value="P">Pasaporte</MenuItem>
              <MenuItem value="O">Otros</MenuItem>
            </Select>
          </FormControl>

          <TextField
            label="Nombre"
            value={data.nombre}
            onChange={(e) => {
              const updatedFormData = [...formData];
              updatedFormData[index].nombre = e.target.value;
              setFormData(updatedFormData);
            }}
            fullWidth
            margin="normal"
          />

          <TextField
            label="Primer Apellido"
            value={data.apellido1}
            onChange={(e) => {
              const updatedFormData = [...formData];
              updatedFormData[index].apellido1 = e.target.value;
              setFormData(updatedFormData);
            }}
            fullWidth
            margin="normal"
          />

          <TextField
            label="Segundo Apellido"
            value={data.apellido2}
            onChange={(e) => {
              const updatedFormData = [...formData];
              updatedFormData[index].apellido2 = e.target.value;
              setFormData(updatedFormData);
            }}
            fullWidth
            margin="normal"
          />

          <TextField
            label="Fecha de Nacimiento"
            type="date"
            InputLabelProps={{ shrink: true }}
            value={data.fechaNacimiento}
            onChange={(e) => {
              const updatedFormData = [...formData];
              updatedFormData[index].fechaNacimiento = e.target.value.replace(/-/g, "");
              setFormData(updatedFormData);
            }}
            fullWidth
            margin="normal"
          />

          <TextField
            label="País de Nacionalidad"
            value={data.paisNacionalidad}
            onChange={(e) => {
              const updatedFormData = [...formData];
              updatedFormData[index].paisNacionalidad = e.target.value;
              setFormData(updatedFormData);
            }}
            fullWidth
            margin="normal"
          />

          <TextField
            label="Fecha de Entrada"
            type="date"
            InputLabelProps={{ shrink: true }}
            value={data.fechaEntrada}
            onChange={(e) => {
              const updatedFormData = [...formData];
              updatedFormData[index].fechaEntrada = e.target.value.replace(/-/g, "");
              setFormData(updatedFormData);
            }}
            fullWidth
            margin="normal"
          />

          <TextField
            label="Hora de Entrada"
            type="time"
            InputLabelProps={{ shrink: true }}
            value={data.horaEntrada}
            onChange={(e) => {
              const updatedFormData = [...formData];
              updatedFormData[index].horaEntrada = e.target.value.replace(":", "");
              setFormData(updatedFormData);
            }}
            fullWidth
            margin="normal"
          />

          <TextField
            label="Fecha de Salida"
            type="date"
            InputLabelProps={{ shrink: true }}
            value={data.fechaSalida}
            onChange={(e) => {
              const updatedFormData = [...formData];
              updatedFormData[index].fechaSalida = e.target.value.replace(/-/g, "");
              setFormData(updatedFormData);
            }}
            fullWidth
            margin="normal"
          />

          <TextField
            label="Hora de Salida"
            type="time"
            InputLabelProps={{ shrink: true }}
            value={data.horaSalida}
            onChange={(e) => {
              const updatedFormData = [...formData];
              updatedFormData[index].horaSalida = e.target.value.replace(":", "");
              setFormData(updatedFormData);
            }}
            fullWidth
            margin="normal"
          />
        </div>
      ))}

      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={handleSubmit}
      >
        Generar Archivo para Mossos
      </Button>
    </Container>
  );
};

export default FormularioDNI;

