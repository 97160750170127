import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {addAiGenerations, addPromptSuggestions, errorAiGenerations, PromptSuggestion} from "redux/reducers/editor";
import {remove_bg_url, space_ai_generations_url} from "utils/urls";
import {requests as api_requests} from 'api/agent';
import {SuggestPromptsResponse} from "components/panels/generative_ai_panel";
import {addCallback, subscribeToResults} from "utils/channels";

export const loadAiGenerations = (spaceId: string) => {

  const dispatch = useDispatch();
  const generativePrompt = useSelector((state:any) => state.editor.generativePrompt)
  const originalProps:PromptSuggestion[] = Object.entries(generativePrompt?.props||({} as any)).map(([title, {prompt}]) => ({title, prompt}))

  useEffect(() => {
    if(!spaceId)
      return

    api_requests.get(space_ai_generations_url({space_id: spaceId})).then((response) => {

      const r = response.reduce((acc,item) => {
        if(item.type != 'image_asset')
          return acc;

        const {uuid, urls,prompt} = item;
        return acc.concat(urls.slice(-1).map((url,_idx)=>({url, prompt, id: uuid})))
      },[]);

      dispatch(addAiGenerations(r))

      const p = response.reduce((acc,item) => {
        if((!item.suggestions) || item.type != 'suggest_prompts')
          return acc;

        const {suggestions}:{suggestions:SuggestPromptsResponse} = item as any;
        return acc.concat(Object.entries(suggestions||{}).map(([title, {prompt}]) => ({title, prompt})));
      },[])

      dispatch(addPromptSuggestions(originalProps.concat(p) as any))
    });
  }, [spaceId]);

  useEffect(() => {
    if(!spaceId)
      return;
    const unsubscribe = subscribeToResults('Space', spaceId, handleResult(dispatch, spaceId), handleError(dispatch, spaceId));
    return unsubscribe;
  }, [spaceId]);


}

export const handleResult = (dispatch, spaceId) => (payload) => {
  if(payload.model == 'sdxl'){
    payload.output.forEach((url) => removeBg(url, spaceId, dispatch))
    return;
  }

  const newResults = [{url: payload.output.slice(-1)[0], prompt: payload.prompt, id: payload.uuid}];

  dispatch(addAiGenerations(newResults))
}

export const handleError = (dispatch, _spaceId) => (payload) => {

  const errorResults = [{id: payload.uuid}];

  dispatch(errorAiGenerations(errorResults))
}


export const removeBg = (url, spaceId, dispatch) => {

  const csrf_token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

  fetch(remove_bg_url({space_id: spaceId}), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrf_token,
    },
    body: JSON.stringify({url: url})
  }).then(response => response.json())
    .then(data => {
      console.log('Remove BG Success:', data);
      addCallback(data.uuid, handleResult(dispatch, spaceId), handleError(dispatch, spaceId));
    })
    .catch((error) => {
      console.error('Error:', error);
    });
}


