import { Map } from '../components/map'
import TilesGroup from '../components/tiles/tilesGroup'
import Player2 from '../components/player/player2'
import EnemiesGroup from '../components/enemies/enemiesGroup'
import Controls from '../components/controls/controls'
import LevelText from '../components/levelText'
import MiniMap from '../components/miniMap'
import Phaser from 'phaser'
import {SpaceAttributes} from 'models/space'
import {GlitchGame, GlitchSignPoint, GlitchSignpost} from 'components/glitch/space'
import sortBy from 'lodash/sortBy'
import Layer from 'game/components/glitx/layer'
import SignPost, {nextLevel, Spot} from 'game/components/glitx/sign_post'
import PlatformLine from 'game/components/glitx/platform_line'
import Deco, {performAction} from 'game/components/glitx/deco'
import {parseGradients} from 'components/glitch/utils'
import {AvatarAttributes} from 'models/avatar'
import {LoadingCallbacks, ViwocScene} from 'packs/game'
import {START_SCENE_EVENT, WORLD_FLOOR_LABEL} from 'game/game_constants'

export const CATEGORY_PLAYER = 0x0001;
export const CATEGORY_PLATFORM = 0x0002;
export const CATEGORY_WALL = 0x0004;
export const CATEGORY_LADDER = 0x0008;
export const CATEGORY_PICKABLE = 0x0010;
export const CATEGORY_OTHER_PLAYER = 0x0020;

export const WALL_THICKNESS = 64; // Thickness of the walls

function interpolate(template: string, values: Record<string, string>): string {
  if(!template) return '';
  return template.replace(/\${(.*?)}/g, (_, key) => values[key]);
}

export default class MainScene extends Phaser.Scene implements ViwocScene {
  player: Player2
  tilesGroup: TilesGroup
  cursors: Phaser.Types.Input.Keyboard.CursorKeys
  enemiesGroup: EnemiesGroup
  controls: Controls
  level: number
  miniMap: MiniMap
  layerGroups:Layer[] = []
  startedAt: number
  gradientBg: Phaser.GameObjects.Image
  path_template: string
  bgImage: Phaser.GameObjects.Image
  spot: GlitchSignPoint
  avatar: AvatarAttributes

  space: SpaceAttributes
  private _gameData: GlitchGame
  loadingCallbacks: LoadingCallbacks;
  startTime: number

  constructor() {
    super({
      key: 'MainScene'
    })
  }

  get gameData(){
    return this._gameData;
  }

  set gameData(gameData: GlitchGame){
    this._gameData = {
      ...gameData,
      dynamic: {
        ...gameData.dynamic,
        layers: Object.fromEntries(Object.entries(gameData.dynamic.layers).map(([id, layer])=> [id, {...layer,id}]))
      }
    }
  }

  init(props: { level?: number, space?: SpaceAttributes, gameData?: GlitchGame, path_template:string, spot: GlitchSignPoint, avatar: AvatarAttributes, loadingCallbacks: LoadingCallbacks}) {
    const { level = 0 } = props
    this.level = Map.calcCurrentLevel(level)
    this.path_template = props.path_template;
    this.avatar=props.avatar;
    this.loadingCallbacks=props.loadingCallbacks;

    if(props.gameData)
      this.gameData = props.gameData;

    if(props.space){
      this.space = props.space;
      this.gameData = props.space.location.metadata;
    }

    this.spot=props.spot
  }

  get sortedLayers(){
    return sortBy(Object.values(this.gameData.dynamic.layers), layer=> layer.z)
  }

  findClosestPlatformLine(layer:Layer, x:number, y:number):PlatformLine|null{

    return layer.platform_lines.reduce((closest:PlatformLine|null, line:PlatformLine)=>{
      if(!closest)return line;
      let old_dist = Phaser.Math.Distance.Between(x, y, closest.start.x, closest.start.y);
      let dist = Phaser.Math.Distance.Between(x, y, line.start.x, line.start.y);
      if(dist < old_dist)
        return line
      return closest;
    },null)
  }

  createBackgroundGradient(colorStops: string[] = ['#0000FF', '#000000']){
    // Define the gradient
    let width = this.cameras.main.width;
    let height = this.cameras.main.height;
    let gradient = this.textures.createCanvas('gradientBG', width, height);
    if(!gradient)
      return;
    let ctx = gradient.getContext();

    // Create a linear gradient - (x0, y0, x1, y1)
    // Vertical gradient: same x, y0 = 0, y1 = height
    let grd = ctx.createLinearGradient(0, 0, 0, height);

    // Add color stops
    colorStops.forEach((color, idx) => {
      try {
        grd.addColorStop(idx, color);
      } catch(e){
        console.error(e)
      } 
    });

    // Fill with gradient
    ctx.fillStyle = grd;
    ctx.fillRect(0, 0, width, height);

    // Refresh texture
    gradient.refresh();

    // Add gradient as image
    this.gradientBg = this.add.image(width / 2, height / 2, 'gradientBG').setScrollFactor(0).setDepth(-10);
    // make gradinent static to be the background of what the camera sees
  }

  createFpsText(){

    const style = { font: '16px Arial', fill: '#ffffff', align: 'center' };
    const text = this.add.text(10, 10, '', style);
    text.setScrollFactor(0);
    text.setDepth(1000);
    this.time.addEvent({
      repeat: -1,
      delay: 1000,
      callback: () => {
        text.setText(`FPS: ${this.game.loop.actualFps.toFixed(2)}`);
      }
    });
  }

  setupCollisionMask(){
    this.matter.world.on('beforeupdate', () => {

      if(this.player.onLadder) return

      if (this.player.body.velocity.y < -3.5) {
        //console.log('going upwards', this.player.body.velocity.y);
        // Player is moving upwards (jumping), so disable collisions with platforms
        this.player.matterBody.collisionFilter.mask = CATEGORY_WALL
      } else {
        //console.log('colliding downwards');
        // Player is moving downwards (falling), so enable collisions with platforms
        this.player.matterBody.collisionFilter.mask = CATEGORY_PLATFORM | CATEGORY_WALL | CATEGORY_LADDER;
      }
    });
    this.matter.world.on('collisionend', (event) => {
      //console.log('collisionend');
      // Re-enable collisions with platforms
      //   const { bodyA, bodyB } = event.pairs[0];
      //   const playerBody = [bodyA, bodyB].find(body => body.label === 'player');
      //   const platformBody = [bodyA, bodyB].find(body => body.label === 'platform_line');
      // if(playerBody && platformBody){
      //   platformBody.isSensor = false;
      // }
      // setTimeout(() => {
      //   const body_and_platform_overlap = this.matter.overlap(this.player, this.tilesGroup.platforms);
      //   this.player.matterBody.collisionFilter.mask = CATEGORY_PLATFORM | CATEGORY_WALL;
      // }, 100);
    })
  }

  setupCollisions(){
    this.matter.world.on('collisionstart', (event) => {
      event.pairs.forEach(pair => {
        const { bodyA, bodyB } = pair;
        const playerBody = [bodyA, bodyB].find(body => body.label === 'player');
        const platformBody = [bodyA, bodyB].find(body => body.label === 'platform_line');

        if (playerBody && platformBody) {
          if (playerBody.velocity.y < 0) {
            // Player is moving downwards, allow standing on the platform
            platformBody.isSensor = true;
            console.log('colliding up', {playerBody, platformBody});
          } else {
            // Player is moving upwards, allow jumping through
            platformBody.isSensor = false;
            console.log('colliding down', {playerBody, platformBody});
          }

        }
      });
    });

    this.matter.world.on('collisionend', (event:Phaser.Physics.Matter.Events.CollisionStartEvent) => {
      event.pairs.forEach(pair => {
        const { bodyA, bodyB } = pair;
        const platform = [bodyA, bodyB].find(body => body.label === 'platform_line');
        const player = [bodyA, bodyB].find(body => body.label === 'player');

        if(platform && player){
          platform.isSensor = false;
          console.log('collisionend', {platform, player});
        }
      });
    });
  }

  create() {
    (window as any).scene = this;
    // update the window URL with the current level in the hash like #/${level}
    //window.location.hash = this.space ? `#/${this.space.uuid}` : `#/${this.gameData.tsid}`
    const newPath = interpolate(this.path_template, {id: this.space ? this.space.uuid : this.gameData.tsid})
    console.log('newPath', newPath)
    window.history.pushState(null, '', newPath)

    this.startedAt = Date.now()

    const map = new Map(this.level)

    this.createFpsText()

    if(this.gameData.gradient){
      const colorGradients = parseGradients(this.gameData.gradient || {})
      this.createBackgroundGradient([colorGradients.top,colorGradients.bottom]);
    } else if(this.gameData.background_image?.url){
      this.bgImage = this.add.image(0, 0, 'bgImage').setOrigin(0, 0).setDepth(-10);
    }

    this.cameras.main.fadeIn()

    this.input.addPointer(1)
    this.cursors = this.input.keyboard.createCursorKeys()
    // TODO destroy/tear down layers
    this.layerGroups=[];

    const width= this.gameData.dynamic.r - this.gameData.dynamic.l;
    const height= this.gameData.dynamic.b - this.gameData.dynamic.t;

    this.cameras.main.setBounds(0, 0, width, height)
    this.matter.world.setBounds(0, 0, width, height)

    let middleground:Layer;

    for(let layer of this.sortedLayers){
      const l= new Layer(this, layer, this.gameData)
      if(layer.name == 'middleground') 
        middleground = l;
      this.layerGroups.push(l)
    }

    this.createWorldBounds(width, height)

    const start_spot = middleground.signposts?.find(({signpost}) => signpost.is_start_of_game)?.signpost || middleground.signposts?.[0]?.signpost;

    this.spot = this.spot || start_spot || {x:0,y:0};

    const playerDepth = this.layerGroups.indexOf(middleground)*1000+999;
    this.player = new Player2(this, {spot: this.spot, texture: `avatar-0`, avatar: this.avatar});
    this.player.setDepth(playerDepth);

    (window as any).player=this.player;

    middleground.add(this.player)
    //TODO this.physics.add.collider(middleground, this.player)

    this.enemiesGroup = new EnemiesGroup(this, map.info)
    //const coinGroup = new CoinGroup(this, map.info.filter((el: TilesConfig) => el.type === 'coin'))

    this.controls = new Controls(this);

    Object.values(this.controls.buttons).forEach(b => b.setDepth(1000000));

    const levelText = new LevelText(this, this.gameData.label)
    levelText.setDepth(playerDepth+1);

    this.cameras.main.startFollow(this.player)

    middleground.decos.forEach((deco: Deco) => {

      if(!deco.signpoint)return

      deco.setInteractive()
      deco.on('pointerdown', (e) => {
        this.player.halt()
      }, this);
    });

    middleground.signposts.forEach((signpost: SignPost) => {

      signpost.on('pointerdown', async (e) => {

        if(signpost.signpost.password){
          const pwd = await this.showPasswordDialog(signpost.signpost.passwordQuestion);
          if(!pwd || (pwd !== signpost.signpost.password)){
            return;
          }
        }

        if(signpost.signpost.is_end_of_game){
          this.scene.start('EndScene', this)

          return
        }

        if(!signpost.nextGame) return

        this.player.halt()
        nextLevel(this, signpost)

      }, this);

    })

    this.miniMap = new MiniMap(
      this,
      10,
      10,
      Math.min(width / 8, (height / 8) * 2.5),
      height / 8,
      {x:0,y:0,width,height}
    )
    this.miniMap.camera.setVisible(false)
    const toIgnore=[
      levelText,
      this.gradientBg,
      this.bgImage,
      this.controls.buttons.up,
      this.controls.buttons.left,
      this.controls.buttons.right
    ];
    //this.setupCollisions()
    this.setupCollisionMask()

    this.miniMap.setIgnore(toIgnore.filter(Boolean));

    this.miniMap.update(this.player);

    // play background music
    if(this.gameData.background_music){
      this.sound.play('bgMusic', { loop: true })
    }

    // remove the loading screen
    let loadingScreen = document.getElementById('loading-screen')
    if (loadingScreen) {
      loadingScreen.classList.add('transparent')
      this.time.addEvent({
        delay: 1000,
        callback: () => {
          loadingScreen.remove()
        }
      })
    }

    // the resize function
    const resize = () => {
      this.controls.adjustPositions()
      levelText.adjustPosition()
    }

    this.scale.on('resize', (gameSize: any) => {
      if(!this.cameras.main)
        return;
      this.cameras.main.width = gameSize.width
      this.cameras.main.height = gameSize.height
      //this.cameras.resize(gameSize.width, gameSize.height)
      resize()
    })
    resize();

    this.player.place(this.spot, this.layerGroups)

    this.startTime = this.time.now;

    if(this.gameData.event_actions?.length){

      this.gameData.event_actions.forEach(({event,action,parameters}) => {
        if(event == START_SCENE_EVENT){
          performAction(this.player, action,parameters, [0,0])
        }
      })
    }

  }

  createWorldBounds(worldWidth, worldHeight) {
    // Top
    this.matter.add.rectangle(worldWidth / 2, -WALL_THICKNESS / 2, worldWidth, WALL_THICKNESS, {
      isStatic: true, 
      friction: 0, // No friction against other bodies
      frictionStatic: 0, // No static friction
      frictionAir: 0, // No air friction
      label: 'worldRoof',
      collisionFilter: {
        category: CATEGORY_WALL
      }
    });
    // Bottom
    this.matter.add.rectangle(worldWidth / 2, worldHeight + WALL_THICKNESS / 2, worldWidth, WALL_THICKNESS, {
      isStatic: true,
      friction: 0.05, // No friction against other bodies
      frictionStatic: 0.05, // No static friction
      frictionAir: 0, // No air friction
      label: WORLD_FLOOR_LABEL,
      collisionFilter: {
        category: CATEGORY_WALL
      }
    });
    // Left
    this.matter.add.rectangle(-WALL_THICKNESS / 2, worldHeight / 2, WALL_THICKNESS, worldHeight, {
      isStatic: true,
      friction: 0, // No friction against other bodies
      frictionStatic: 0, // No static friction
      frictionAir: 0, // No air friction
      label: 'worldWall',
      collisionFilter: {
        category: CATEGORY_WALL,

      }
    });
    // Right
    this.matter.add.rectangle(worldWidth + WALL_THICKNESS / 2, worldHeight / 2, WALL_THICKNESS, worldHeight, {
      isStatic: true,
      friction: 0, // No friction against other bodies
      frictionStatic: 0, // No static friction
      frictionAir: 0, // No air friction
      label: 'worldWall',
      collisionFilter: {
        category: CATEGORY_WALL
      }
    });

  }

  update() {
    this.controls.update()
    this.enemiesGroup.update()
    this.player.update(this.cursors, this.controls)
    this.miniMap.update(this.player)

    const width= this.gameData.dynamic.r - this.gameData.dynamic.l;
    const height = this.gameData.dynamic.b - this.gameData.dynamic.t;

    const percentScolledX = this.cameras.main.scrollX / (width - this.cameras.main.width);
    const percentScolledY = this.cameras.main.scrollY / (height - this.cameras.main.height);

    this.layerGroups.forEach((layer:Layer, idx)=>{
      //if(layer.layer.name == 'middleground') return;
      const offsetX = (width - layer.layer.w) * percentScolledX;
      const offsetY = (height - layer.layer.h) * percentScolledY;
      layer.parallaxByOffset(offsetX, offsetY)
    })

  }

  showPasswordDialog(passwordQuestion?:string): Promise<string|undefined> {
    return new Promise((accept, reject) => {
      const dialogHtml = `
        <div style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); background-color: rgba(0, 0, 0, 0.7); padding: 20px; border-radius: 10px; z-index: 1000;">
          <p style="color: white; margin-bottom: 10px;">Enter Password:</p>
          ${passwordQuestion ? `<p style="color: white; margin-bottom: 10px;">${passwordQuestion}</p>` : ''}
          <input type="password" id="passwordInput" style="width: 100%; margin-bottom: 10px; padding: 5px;">
          <div style="display: flex; justify-content: space-between;">
            <button id="submitButton" style="border-radius: 5px; padding: 5px; background: black; color: white;">Submit</button>
            <button id="cancelButton" style="border-radius: 5px; padding: 5px; background: black; color: white;">Cancel</button>
          </div>
        </div>
      `;

      const dialogContainer = document.createElement('div');
      dialogContainer.innerHTML = dialogHtml;
      document.body.appendChild(dialogContainer);

      const inputField = dialogContainer.querySelector('#passwordInput') as HTMLInputElement;
      const submitButton = dialogContainer.querySelector('#submitButton') as HTMLButtonElement;
      const cancelButton = dialogContainer.querySelector('#cancelButton') as HTMLButtonElement;

      const cleanup = () => {
        document.body.removeChild(dialogContainer);
      };

      cancelButton.onclick = () => {
        cleanup();
        accept(undefined)
      }

      submitButton.onclick = () => {
        const password = inputField.value;
        cleanup();
        accept(password);
      };

      inputField.onkeydown = (event) => {
        if (event.key === 'Enter') {
          submitButton.click();
        }
      };

      // Focus on the input field
      inputField.focus();
    });
  }
}
