import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { SpriteCard } from 'components/sprites/sprite_card';
import el_svg from 'images/pointer-click.svg';

export default function Interact({sampleNpc, selectedSprite, handleNext}) {
  return <Dialog
    className="game_welcome step4"
    open={true}
    onClose={()=>handleNext(4)}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    maxWidth="sm"
  >
    <DialogTitle id="alert-dialog-title-2" disableTypography={true}>
      <T k="game_welcome.interact.title">Interact with objects by clicking on them.</T>
  </DialogTitle>

    <DialogContent>
      <div className='inner-content' style={{position: 'relative'}}>
        {sampleNpc && <SpriteCard
          playing={true}
          sprite={sampleNpc}
          anim={'right'}
          hideBtns={true}
          html={{className:"img", style:{filter: 'drop-shadow(0 0 6px cyan)'}}}
          key={selectedSprite.id}
          ></SpriteCard>}
        <img src={el_svg} style={{maxWidth: '100px', position: 'absolute', bottom: 0, left: '30%'}}></img>
      </div>
    </DialogContent>
    <DialogActions>
      <Button onClick={()=>{if(selectedSprite)handleNext(4)}} color="primary">
        Next
      </Button>
    </DialogActions>
  </Dialog>

}
